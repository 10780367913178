import { render, staticRenderFns } from "./latest.vue?vue&type=template&id=0e4dfe18&scoped=true&"
import script from "./latest.vue?vue&type=script&lang=js&"
export * from "./latest.vue?vue&type=script&lang=js&"
import style0 from "./latest.vue?vue&type=style&index=0&id=0e4dfe18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e4dfe18",
  null
  
)

export default component.exports