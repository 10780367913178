<template>
    <div class="latest">
        <img :src="img" alt="公告图片" />
    </div>
</template>
<script>
import { selectNew } from '@/api'
export default {
    name: 'latest',
    data(){
        return{
            img: ''
        }
    },
    created(){
        selectNew().then(res => {
            this.img = res.noticePicture
        })
    },
}
</script>
<style lang="scss" scoped>
.latest{
    width: 1300px;
    margin: auto;
    text-align: center;
    img{
        margin: 30px 0;
        width: 912px;
        height: 1300px;
    }
}
</style>